/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { formatDateToYYYYMMDD, showTimeInAgoFormat } from '../../Utils/Time'
import { Box, Button, Card, CardContent, Snackbar, Stack, Table, TableBody, TableContainer, TextField, Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import DrawerNav from '../DrawerNav/DrawerNav'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Delete, Edit, RemoveRedEye, VisibilityOff } from '@mui/icons-material'
import TableHead from '@mui/material/TableHead'

import { useLocation } from 'react-router-dom'
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#262726',
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

function SearchQuestion() {
    const [searchText, setSearchText] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const navigate = useNavigate()
    const handleLogin = () => {
        navigate('/login')
    }

    const handleSearch = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/search/v1?text=${searchText}`)
            if (response.ok) {
                const data = await response.json()
                console.log(' DATA : ', data)
                setSearchResults(data.data.questionsList)
            } else {
                throw new Error('Failed to fetch questions')
            }
        } catch (error) {
            console.error('Error fetching search results:', error)
        }
    }

    const editEntryHandler = (id, action) => {
        navigate(`/modify-question?questionId=${id}&action=${action}`)
    }

    const { id } = useParams()
    const query = useQuery()
    const topicName = query.get('name')
    const subjectID = query.get('subjectID')
    const subjectName = query.get('subjectName')
    const [apiCalled, setApiCalled] = useState(false)
    const [examsList, setExamsList] = useState([])

    const sectionsList = ['English', 'Grammar', 'English Exam', 'English Verbs']

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const navigateToRoute = () => {
        navigate(`/add-exam?topicID=${id}&subjectID=${subjectID}&subjectName=${subjectName}&topicName=${topicName}`)
    }

    const handleTopicClick = (id, name) => {
        navigate(`/exam/${id}?name=${name}`)
    }

    const handleResourceClick = (id, action, type, name, description) => {
        navigate(`/edit-resource?id=${id}&action=${action}&type=${type}&name=${name}&description=${description}`)
    }

    const [searchQuery, setSearchQuery] = useState('')
    const [filteredTopics, setFilteredTopics] = useState([])

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredTopics(examsList)
        } else {
            setFilteredTopics(examsList.filter((topic) => topic.name.toLowerCase().includes(searchQuery.toLowerCase()) || (topic.description && topic.description.toLowerCase().includes(searchQuery.toLowerCase()))))
        }
    }, [searchQuery, examsList])

    const handleEditQuestion = (id) => {
        navigate('/edit-question?id=' + id)
    }

    const handleDeleteQuestion = (id, question) => {
        navigate('/delete-question?id=' + id + `&question=${question}`)
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='pb-48'>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
                            <Typography variant='h4' sx={{ fontWeight: 500 }}>
                                Search Question
                            </Typography>
                            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <TextField label='Search' sx={{ mr: 4, width: '400px' }} value={searchText} onChange={(e) => setSearchText(e.target.value)} variant='outlined' />
                                <Button variant='contained' size='large' onClick={handleSearch}>
                                    Search
                                </Button>
                            </Stack>
                        </Stack>

                        <Card>
                            <TableContainer sx={{ overflow: 'unset' }}>
                                <Table sx={{ width: '100%' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Question</TableCell>
                                            <TableCell>Answer</TableCell>
                                            <TableCell>Exam</TableCell>
                                            <TableCell key={9}>
                                                <Typography variant='subtitle1'>Edit</Typography>
                                            </TableCell>
                                            <TableCell key={10}>
                                                <Typography variant='subtitle1'>Delete</Typography>
                                            </TableCell>
                                            <TableCell>Added</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {searchResults.length > 0 &&
                                            searchResults.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell sx={{ width: '30%' }}>{row.question}</TableCell>
                                                    <TableCell>{row.correctOption}</TableCell>
                                                    <TableCell>{row.exam && row.exam.name}</TableCell>
                                                    <TableCell onClick={() => handleEditQuestion(row._id)}>
                                                        <Box component='img' alt='Edit Icon' src='/navbar/ic_pencil.svg' sx={{ height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} />
                                                    </TableCell>
                                                    <TableCell onClick={() => handleDeleteQuestion(row._id, row.question)}>
                                                        <Box component='img' alt='Edit Icon' src='/navbar/ic_trash.svg' sx={{ height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} />
                                                    </TableCell>
                                                    <TableCell>{formatDateToYYYYMMDD(row.createdAt)}</TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default SearchQuestion

/* eslint-disable jsx-a11y/no-static-element-interactions */
// import React, { useState } from 'react'
// /* eslint-disable jsx-a11y/click-events-have-key-events */
// import { useNavigate } from 'react-router-dom'

// import { Box } from '@mui/material'
// import Card from '@mui/material/Card'
// import Stack from '@mui/material/Stack'
// import Table from '@mui/material/Table'
// import Button from '@mui/material/Button'
// import TableRow from '@mui/material/TableRow'
// import Container from '@mui/material/Container'
// import TableBody from '@mui/material/TableBody'
// import TableCell from '@mui/material/TableCell'
// import TableHead from '@mui/material/TableHead'
// import TextField from '@mui/material/TextField'
// import Typography from '@mui/material/Typography'
// import TableContainer from '@mui/material/TableContainer'

// import { isAuthenticated } from 'src/hooks/use-auth'

// import { fToNow } from 'src/utils/format-time'

// import { BASE_URL } from 'src/api/Constants'

// import Scrollbar from 'src/components/scrollbar'
