import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';
import DrawerNav from '../DrawerNav/DrawerNav';
import { BASE_URL } from '../../Api/Constants';

export default function EditUser() {
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const onboardingId = queryParams.get('id');

    // const [title, setTitle] = useState('');
    const [name, setName] = useState('')
    const [description, setDescription] = useState('');
    const [points, setPoints] = useState(0)

    useEffect(() => {
        const fetchOnboardingData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/profile/${onboardingId}`);
                if (response.ok) {
                    const data = await response.json();
                    console.log('Data : ', data)
                    const { coins, username } = data.data.userData;
                    setName(username);
                    setPoints(coins)
                    // setDescription(description);
                } else {
                    console.error('Failed to fetch exam data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchOnboardingData();
    }, [onboardingId]);

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/profile/${onboardingId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    coins: points
                }),
            });

            const data = await response.json();

            if (response.ok) {
                console.log(data); // Success message
                navigate('/joined-users')
            } else {
                console.error(data.message); // Error message
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant="h4" sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Edit User
                    </Typography>
                    <TextField
                        label="Name"
                        value={name}
                        disabled={true}
                        onChange={(event) => setName(event.target.value)}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    />
                    <TextField
                        label="Points"
                        type='number'
                        value={points}
                        onChange={(event) => setPoints(event.target.value)}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    />
                    <Box mt={2}>
                        <Button variant="contained" color="primary" onClick={handleSaveChanges}>
                            Save Changes
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
