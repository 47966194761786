/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios'
import { Box, Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel, Stack } from '@mui/material'
// eslint-disable-next-line import/no-unresolved
import * as XLSX from 'xlsx'
import React, { useEffect, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Card, Table, TableRow, TableBody, TableCell, TableHead } from '@mui/material'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export default function ExportExam() {
    const navigate = useNavigate()
    const { id } = useParams()
    const query = useQuery()
    const examName = query.get('name')

    const exportExam = (type) => {
        fetch(`${BASE_URL}/api/admin/features/v1/export-exam?id=${id}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to download the Excel file')
                }
                return response.blob()
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                const currentDate = new Date()
                const options = { month: 'long', day: 'numeric' }
                const formattedDate = currentDate.toLocaleDateString('en-US', options).replace(/,/g, '_').replace(/\s+/g, '_')
                const updatedFilePath = `${examName}_${formattedDate}`
                a.download = `${updatedFilePath}.xlsx`
                a.click()
                window.URL.revokeObjectURL(url)
            })
            .catch((err) => {
                console.error('Error downloading the Excel file:', err)
            })
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <h1 className='text-4xl font-bold my-4'>Export {examName}</h1>
                <Box sx={{ maxWidth: '100%', mt: 4 }}>
                    <Button variant='contained' color='warning' sx={{ mr: 2 }} onClick={() => navigate(-1)}>
                        Return to Exam page
                    </Button>
                    <Button variant='contained' color='primary' onClick={exportExam}>
                        Export Exam
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
