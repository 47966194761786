import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import './Task.css'

const renderQuestionText = (text) => {
    // Replace new lines with <br />
    let formattedText = text.replace(/\n/g, '<br />')

    // Replace sequences for underline, bold, and italic
    formattedText = formattedText
        .replace(/-\*([^*]+)\*-/g, '<u>$1</u>') // -*text*- for underline
        .replace(/\*\*([^*]+)\*\*/g, '<b>$1</b>') // **text** for bold
        .replace(/\*([^*]+)\*/g, '<i>$1</i>') // *text* for italic

    return <div dangerouslySetInnerHTML={{ __html: formattedText }} />
}

export const Task = ({ id, title }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

    const style = {
        transition,
        transform: CSS.Transform.toString(transform)
    }

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} className='task'>
            {renderQuestionText(title)}
        </div>
    )
}
