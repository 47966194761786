import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import DrawerNav from '../DrawerNav/DrawerNav'
import { BASE_URL } from '../../Api/Constants'
import { useNavigate } from 'react-router-dom'

export default function AddSubtopic() {
    const [name, setName] = useState('')
    const [topic, setTopic] = useState('')
    const [description, setDescription] = useState('')
    const navigate = useNavigate()
    const [topics, setTopics] = useState([])

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/user/topics`)
                const data = await response.json()
                if (response.ok) {
                    console.log('Data : ', data)
                    setTopics(data.data.topicsList)
                } else {
                    console.error(data.message)
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }

        fetchTopics()
    }, [])

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/subtopic`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name,
                    topic,
                    description
                })
            })

            const data = await response.json()

            if (response.ok) {
                setName('')
                setDescription('')
                navigate('/')
            } else {
                console.error(data.message)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Add New Subtopic
                    </Typography>
                    {/* <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel id='select-topic-label'>Choose Section</InputLabel>
                        <Select labelId='select-topic-label' id='select-topic' value={section} onChange={(event) => setSection(event.target.value)} label='Choose Section'>
                            {['General Studies', 'Civil Services Aptitude', 'Current Affairs', 'Past Year Questions'].map((topic, index) => (
                                <MenuItem key={index} value={topic}>
                                    {topic}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                    <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel id='select-topic-label'>Choose Topic</InputLabel>
                        <Select labelId='select-topic-label' id='select-topic' value={topic} onChange={(event) => setTopic(event.target.value)} label='Choose Topic'>
                            {topics.map((topic, index) => (
                                <MenuItem key={index} value={topic}>
                                    {topic}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField label='Subtopic Name' value={name} onChange={(event) => setName(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <TextField label='Subtopic Description' value={description} onChange={(event) => setDescription(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <Box mt={2}>
                        <Button variant='contained' color='success' onClick={handleSaveChanges}>
                            Add Subtopic
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
