import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Typography, Button, Card, CardContent } from '@mui/material';

const questions = [
    { id: 1, question: 'What is 2 + 2?', options: [2, 3, 4, 5], correctAnswer: 4 },
    { id: 2, question: 'What is the capital of France?', options: ['Berlin', 'Madrid', 'Paris', 'Lisbon'], correctAnswer: 'Paris' },
    { id: 3, question: 'What is the chemical symbol for water?', options: ['H2O', 'O2', 'CO2', 'NaCl'], correctAnswer: 'H2O' },
    { id: 4, question: 'What is 10 / 2?', options: [2, 3, 5, 6], correctAnswer: 5 },
    { id: 5, question: 'Who wrote "To Kill a Mockingbird"?', options: ['Harper Lee', 'Mark Twain', 'J.K. Rowling', 'Jane Austen'], correctAnswer: 'Harper Lee' },
    { id: 6, question: 'What is the largest planet in our solar system?', options: ['Earth', 'Mars', 'Jupiter', 'Saturn'], correctAnswer: 'Jupiter' },
    { id: 7, question: 'What is the square root of 64?', options: [6, 7, 8, 9], correctAnswer: 8 },
    { id: 8, question: 'What is the speed of light?', options: ['299,792,458 m/s', '150,000,000 m/s', '100,000 km/s', '50,000 km/s'], correctAnswer: '299,792,458 m/s' },
    { id: 9, question: 'Who painted the Mona Lisa?', options: ['Vincent Van Gogh', 'Pablo Picasso', 'Leonardo da Vinci', 'Claude Monet'], correctAnswer: 'Leonardo da Vinci' },
    { id: 10, question: 'What is the smallest prime number?', options: [1, 2, 3, 4], correctAnswer: 2 },
];

const optionVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: (i) => ({
        opacity: 1,
        x: 0,
        transition: {
            delay: i * 0.2,
            duration: 0.5,
            ease: 'easeOut'
        }
    })
};

const QuizPage = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState(Array(questions.length).fill(null));
    const [isCorrect, setIsCorrect] = useState(null);
    const [showReport, setShowReport] = useState(false);
    const [score, setScore] = useState(0);

    const handleAnswerSelection = (answer) => {
        if (answers[currentQuestion] === null) {
            const isCorrectAnswer = answer === questions[currentQuestion].correctAnswer;
            setAnswers(prevAnswers => {
                const newAnswers = [...prevAnswers];
                newAnswers[currentQuestion] = { answer, isCorrect: isCorrectAnswer };
                return newAnswers;
            });
            setIsCorrect(isCorrectAnswer);
            if (isCorrectAnswer) {
                setScore((prevScore) => prevScore + 1);
            }
        }
    };

    const handleNext = () => {
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion((prev) => prev + 1);
            setIsCorrect(null);
        } else {
            setShowReport(true);
        }
    };

    const handlePrev = () => {
        setCurrentQuestion((prev) => (prev > 0 ? prev - 1 : prev));
        setIsCorrect(null);
    };

    const handleQuestionClick = (index) => {
        setCurrentQuestion(index);
        setIsCorrect(null);
    };

    const handleRetake = () => {
        setCurrentQuestion(0);
        setAnswers(Array(questions.length).fill(null));
        setIsCorrect(null);
        setShowReport(false);
        setScore(0);
    };

    return (
        <div className="relative bg-gradient-to-r from-gray-100 to-gray-200 min-h-screen flex flex-col">
            {showReport ? (
                <motion.div
                    initial={{ opacity: 0, y: -150 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="flex flex-col items-center justify-center flex-1"
                >
                    <Card className="w-4/5 md:w-2/5 p-4 shadow-xl bg-white">
                        <CardContent>
                            <Typography variant="h4" component="div" className="text-center mb-4 font-bold">
                                Quiz Completed!
                            </Typography>
                            <Typography variant="h6" component="div" className="text-center mb-2">
                                Your Score: {score} / {questions.length}
                            </Typography>
                            <Typography variant="body1" component="div" className="text-center mb-4">
                                Percentage: {((score / questions.length) * 100).toFixed(2)}%
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleRetake}
                                className="w-full py-2"
                                sx={{
                                    backgroundColor: '#4CAF50',
                                    color: 'white',
                                    borderRadius: '20px',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        backgroundColor: '#45a049'
                                    }
                                }}
                            >
                                Retake Quiz
                            </Button>
                        </CardContent>
                    </Card>
                </motion.div>
            ) : (
                <div className="flex flex-1 p-4">
                    <div className="w-1/5 p-2 md:p-4">
                        <div className="grid grid-cols-5 gap-2 md:gap-4">
                            {questions.map((q, index) => (
                                <div className="relative" key={q.id}>
                                    <motion.button
                                        className={`w-12 h-12 md:w-16 md:h-16 flex items-center justify-center rounded-2xl ${currentQuestion === index ? 'bg-blue-300' : 'bg-gray-300'}`}
                                        onClick={() => handleQuestionClick(index)}
                                        whileHover={{ scale: 1.1 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        {q.id}
                                        {answers[index] && (
                                            <span
                                                className={`absolute top-2 right-0 w-1 h-1 md:w-2 md:h-2 rounded-full ${answers[index].isCorrect ? 'bg-green-500' : 'bg-red-500'}`}
                                            ></span>
                                        )}
                                    </motion.button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-0.5 bg-gray-300 mx-2"></div>
                    <div className="w-4/5 p-2 md:p-4 flex flex-col items-center">
                        <motion.div
                            key={currentQuestion}
                            initial={{ opacity: 0, y: -150 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 50 }}
                            transition={{ duration: 0.5 }}
                            className="w-full"
                        >
                            <Typography variant="h5" component="div" sx={{ mb: 4 }}>
                                {questions[currentQuestion].question}
                            </Typography>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
                                {questions[currentQuestion].options.map((option, index) => (
                                    <motion.button
                                        key={index}
                                        onClick={() => handleAnswerSelection(option)}
                                        className={`p-2 md:p-4 rounded-lg ${answers[currentQuestion] && answers[currentQuestion].answer === option ? (answers[currentQuestion].isCorrect ? 'bg-green-400' : 'bg-red-400') : 'bg-gray-300'}`}
                                        whileHover={{ scale: 1.05 }}
                                        transition={{ duration: 0.3 }}
                                        custom={index}
                                        initial="hidden"
                                        animate="visible"
                                        variants={optionVariants}
                                        disabled={answers[currentQuestion] !== null}
                                    >
                                        {option}
                                    </motion.button>
                                ))}
                            </div>
                        </motion.div>
                        <div className="mt-4 md:mt-8 flex space-x-4">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handlePrev}
                                disabled={currentQuestion === 0}
                                sx={{
                                    backgroundColor: '#4CAF50',
                                    color: 'white',
                                    padding: '10px 20px',
                                    borderRadius: '20px',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        backgroundColor: '#45a049'
                                    }
                                }}
                            >
                                Previous
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                disabled={answers[currentQuestion] === null}
                                sx={{
                                    backgroundColor: '#4CAF50',
                                    color: 'white',
                                    padding: '10px 20px',
                                    borderRadius: '20px',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        backgroundColor: '#45a049'
                                    }
                                }}
                            >
                                {currentQuestion === questions.length - 1 ? 'Submit' : 'Next'}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuizPage;
