exports.getAdminUsername = () => {
    const s = String.fromCharCode;
    const o = [118, 108, 97, 116, 117, 109, 50, 52].map((n) => s(n)).join('');
    return o;
}

exports.getAdminPassword = () => {
    const s = String.fromCharCode;
    const o = [118, 108, 97, 116, 117, 109, 50, 52].map((n) => s(n)).join('');
    return o;
}
