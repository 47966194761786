import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Grid, Button, TextField, Typography, CircularProgress } from '@mui/material'
import { BASE_URL } from '../../Api/Constants'

export default function EditQuestionModal({ questionId, closeAction }) {
    const location = useLocation()
    const navigate = useNavigate()
    const [question, setQuestion] = useState(null)
    const [editedQuestion, setEditedQuestion] = useState(null)

    useEffect(() => {
        const fetchQuestionDetails = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/admin/question/${questionId}`)
                if (response.ok) {
                    const data = await response.json()
                    setQuestion(data.data.question)
                    setEditedQuestion(data.data.question)
                } else {
                    throw new Error('Failed to fetch question details')
                }
            } catch (error) {
                console.error(error)
            }
        }

        if (questionId) {
            fetchQuestionDetails()
        }
    }, [questionId])

    const handleInputChange = (event, field) => {
        setEditedQuestion({
            ...editedQuestion,
            [field]: event.target.value
        })
    }

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/question/${questionId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(editedQuestion)
            })
            if (response.ok) {
                console.log('Question updated successfully')
                closeAction()
            } else {
                throw new Error('Failed to update question')
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Box p={2}>
                {editedQuestion ? (
                    <>
                        <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                            Edit Question
                        </Typography>
                        <TextField multiline label='Question' value={editedQuestion.question} onChange={(event) => handleInputChange(event, 'question')} fullWidth variant='outlined' margin='normal' />
                        <TextField label='Image URL' value={editedQuestion.imageURL} onChange={(event) => handleInputChange(event, 'imageURL')} fullWidth variant='outlined' margin='normal' />
                        <TextField label='Audio URL' value={editedQuestion.audioURL} onChange={(event) => handleInputChange(event, 'audioURL')} fullWidth variant='outlined' margin='normal' />
                        <Typography variant='body1'>Options:</Typography>
                        <Grid container spacing={0.5}>
                            {editedQuestion.options &&
                                editedQuestion.options.map((option, index) => (
                                    <Grid item xs={12} md={6} key={index}>
                                        <TextField
                                            value={option}
                                            onChange={(event) => {
                                                const newOptions = [...editedQuestion.options]
                                                newOptions[index] = event.target.value
                                                setEditedQuestion({
                                                    ...editedQuestion,
                                                    options: newOptions,
                                                    correctOption: newOptions[0]
                                                })
                                            }}
                                            fullWidth
                                            variant='outlined'
                                            margin='normal'
                                            style={{ backgroundColor: option === editedQuestion.correctOption ? '#c0f0c2' : 'white' }}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                        {editedQuestion.options.some((option) => option.trim().length > 0) && (
                            <TextField
                                select
                                sx={{ my: 1 }}
                                name=''
                                label='Correct Option'
                                fullWidth
                                SelectProps={{
                                    native: true
                                }}
                                value={editedQuestion.correctOption}
                                onChange={(event) => handleInputChange(event, 'correctOption')}>
                                {editedQuestion.options.map(
                                    (option, index) =>
                                        option.trim().length > 0 && (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        )
                                )}
                            </TextField>
                        )}
                        <TextField label='Explanation' multiline={true} value={editedQuestion.explanation} onChange={(event) => handleInputChange(event, 'explanation')} fullWidth variant='outlined' margin='normal' />
                        <Box mt={2}>
                            <Button variant='contained' color='error' sx={{ marginRight: '10px' }} onClick={() => closeAction()}>
                                Cancel
                            </Button>
                            <Button variant='contained' color='success' onClick={handleSaveChanges}>
                                Save Changes
                            </Button>
                        </Box>
                    </>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 10, height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </Box>
    )
}
