import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

function CustomThreeDots() {
    return (
        <div className='flex items-center justify-center mt-32 m-auto ml-64'>
            <ThreeDots visible={true} height='100' width='100' color='#202220' radius='15' ariaLabel='three-dots-loading' wrapperStyle={{}} wrapperClass='' />
        </div>
    )
}

export default CustomThreeDots
