import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { FileDownloadOffOutlined, Home, Logout, MusicNote, PanoramaPhotosphere, Person, Report, Search, Subject, SubjectTwoTone, Topic, TopicTwoTone, VerifiedUserRounded } from '@mui/icons-material'
function DrawerNav() {
    const navigate = useNavigate()
    const handleRoute = (url) => {
        navigate('/' + url)
    }

    const isMobile = useMediaQuery('(max-width:600px)')

    const contestListItems = [
        {
            value: 'Home',
            route: '',
            icon: <Home />
        },
        {
            value: 'Add Subject',
            route: 'add-subject',
            icon: <Topic />
        },
        {
            value: 'Add Topic',
            route: 'add-topic-v2',
            icon: <Subject />
        },
        {
            value: 'Add Exam',
            route: 'add-exam-v2',
            icon: <PanoramaPhotosphere />
        },
        {
            value: 'Media Upload',
            route: 'media-upload',
            icon: <MusicNote />
        }
    ]

    const contestListItems2 = [
        {
            value: 'Subject Management',
            route: 'subject-management',
            icon: <Subject />
        },
        {
            value: 'Topic Management',
            route: 'topic-management',
            icon: <Topic />
        },
        {
            value: 'Exam Management',
            route: 'exam-management',
            icon: <PanoramaPhotosphere />
        },
        {
            value: 'Search Question',
            route: 'search-question',
            icon: <Search />
        },
        {
            value: 'Report Management',
            route: 'report-management',
            icon: <Report />
        }
        // {
        //     value: 'User Management',
        //     route: '',
        //     icon: <Person />
        // }
    ]

    const drawerWidth = 300

    return (
        <>
            <CssBaseline />
            <Drawer
                variant='permanent'
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    display: isMobile ? 'none' : 'block',
                    position: 'relative',
                    zIndex: 1
                }}>
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {contestListItems.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {contestListItems2.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        <ListItem key={12} disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    localStorage.clear()
                                    handleRoute('login')
                                }}>
                                <ListItemIcon>
                                    <Logout />
                                </ListItemIcon>
                                <ListItemText primary='Logout' />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </>
    )
}

export default DrawerNav
