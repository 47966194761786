import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { showTimeInAgoFormat } from '../../Utils/Time'
import { Box, Button, Table, TableBody, TextField, TableRow, TableHead, Typography, Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import DrawerNav from '../DrawerNav/DrawerNav'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import './ManagementSubject.css'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#262726',
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

function ManagementSubject() {
    const navigate = useNavigate()
    const [topicsList, setTopicsList] = useState([])
    const [filteredTopics, setFilteredTopics] = useState([])
    const [apiCalled, setApiCalled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    const [searchQuery, setSearchQuery] = useState('')

    const handleEditClick = (id) => {
        navigate('/edit-contest/' + id)
    }

    const getTopicsList = async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }

            await fetch(`${BASE_URL}/api/admin/v1/subjects`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.status === 200) {
                        setTopicsList(data.data.list)
                        setFilteredTopics(data.data.list)
                        setApiCalled(true)
                    } else {
                        setOpenErrorAlert(true)
                    }
                })
                .catch((error) => {
                    console.log('Error : ', error)
                    setOpenErrorAlert(true)
                    setApiCalled(true)
                })
        } catch {
        } finally {
            setShowLoader(false)
            setApiCalled(true)
        }
    }

    useEffect(() => {
        if (!apiCalled) {
            getTopicsList()
        }
    }, [apiCalled])

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredTopics(topicsList)
        } else {
            setFilteredTopics(topicsList.filter((topic) => topic.name.toLowerCase().includes(searchQuery.toLowerCase()) || (topic.description && topic.description.toLowerCase().includes(searchQuery.toLowerCase()))))
        }
    }, [searchQuery, topicsList])

    const sectionsList = ['English', 'Grammar', 'English Exam', 'English Verbs']

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const navigateToRoute = () => {
        navigate('/add-subject')
    }

    const handleSubjectClick = (id, name) => {
        navigate(`/subject/${id}?name=${name}`)
    }

    const handleResourceClick = (id, action, type, name, description) => {
        navigate(`/edit-resource?id=${id}&action=${action}&type=${type}&name=${name}&description=${description}`)
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='pb-48'>
                        <div className='flex justify-between items-center p-4'>
                            <h1 className='text-4xl font-bold'>Subject Management</h1>
                            <div className='space-x-2 flex items-center'>
                                <TextField size='small' variant='outlined' placeholder='Search...' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} sx={{ height: '40px', backgroundColor: 'white' }} />
                                <Button size='medium' variant='contained' color='success' onClick={navigateToRoute} sx={{ height: '40px' }}>
                                    Add Subject
                                </Button>
                            </div>
                        </div>
                        <Table sx={{ width: '100%', marginBottom: 30, marginTop: 5 }} aria-label='customized table'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className='rounded-tl-lg' align='left'>
                                        S.No
                                    </StyledTableCell>
                                    <StyledTableCell align='left'>Subject</StyledTableCell>
                                    <StyledTableCell align='left'>Description</StyledTableCell>
                                    <StyledTableCell align='left'>Topics Count</StyledTableCell>
                                    <StyledTableCell align='left'>Exam Count</StyledTableCell>
                                    <StyledTableCell align='left'>Updated</StyledTableCell>
                                    <StyledTableCell align='left'>Created</StyledTableCell>
                                    <StyledTableCell align='left'>Edit</StyledTableCell>
                                    <StyledTableCell align='left'>Delete</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTopics.length > 0 &&
                                    filteredTopics.map((subject, index) => (
                                        <StyledTableRow key={subject._id} sx={{ border: '1px solid gray' }}>
                                            <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                            <StyledTableCell align='left' className='underline cursor-pointer' onClick={() => handleSubjectClick(subject._id, subject.name)}>
                                                {subject.name}
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>{subject.description || 'No description'}</StyledTableCell>
                                            <StyledTableCell align='left'>{subject.topics ? subject.topics.length : 0}</StyledTableCell>
                                            <StyledTableCell align='left'>{subject.exams ? subject.exams.length : 0}</StyledTableCell>
                                            <StyledTableCell align='left'>{showTimeInAgoFormat(subject.updatedAt)}</StyledTableCell>
                                            <StyledTableCell align='left'>{showTimeInAgoFormat(subject.createdAt)}</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                <Button variant='contained' color='primary' onClick={() => handleResourceClick(subject._id, 'edit', 'subject', subject.name, subject.description)} sx={{ mr: 2 }}>
                                                    Edit
                                                </Button>
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>
                                                <Button variant='contained' color='error' onClick={() => handleResourceClick(subject._id, 'delete', 'subject', subject.name, subject.description)}>
                                                    Delete
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default ManagementSubject
