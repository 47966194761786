import React, { useState, useEffect } from 'react'
import { Box, Button, TextField, Typography, Autocomplete, CircularProgress } from '@mui/material'
import DrawerNav from '../DrawerNav/DrawerNav'
import { BASE_URL } from '../../Api/Constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export default function AddTopicV2() {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [subjects, setSubjects] = useState([])
    const [selectedSubject, setSelectedSubject] = useState(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const fetchSubjects = async () => {
            setLoading(true)
            try {
                const response = await fetch(`${BASE_URL}/api/admin/v2/subjects`)
                const data = await response.json()
                if (response.ok) {
                    console.log('Data : ', data)
                    setSubjects(data.data.list)
                } else {
                    console.error(data.message)
                    toast.error(`Error fetching subjects: ${data.message}`, {
                        position: 'bottom-left'
                    })
                }
            } catch (error) {
                console.error('Error:', error)
                toast.error('Error fetching subjects', {
                    position: 'bottom-left'
                })
            } finally {
                setLoading(false)
            }
        }

        fetchSubjects()
    }, [])

    const handleSaveChanges = async () => {
        if (!selectedSubject) {
            console.error('Subject is required')
            toast.error('Subject is required', {
                position: 'bottom-left'
            })
            return
        }

        try {
            const response = await fetch(`${BASE_URL}/api/admin/v1/topic`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    subjectID: selectedSubject._id,
                    name,
                    description
                })
            })

            const data = await response.json()

            if (response.ok) {
                setName('')
                setDescription('')
                setSelectedSubject(null)
                toast.success('Topic added successfully', {
                    position: 'bottom-left'
                })
                navigate(-1)
            } else {
                console.error(data.message)
                toast.error(`Error adding topic: ${data.message}`, {
                    position: 'bottom-left'
                })
            }
        } catch (error) {
            console.error('Error:', error)
            toast.error('Error adding topic', {
                position: 'bottom-left'
            })
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Add New Topic
                    </Typography>
                    <Autocomplete
                        options={subjects}
                        getOptionLabel={(option) => option.name}
                        value={selectedSubject}
                        onChange={(event, newValue) => setSelectedSubject(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Subject'
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loading ? <CircularProgress color='inherit' size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    )
                                }}
                            />
                        )}
                    />
                    <TextField label='Topic Name' value={name} onChange={(event) => setName(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <TextField label='Topic Description' value={description} onChange={(event) => setDescription(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <Box mt={2}>
                        <Button variant='contained' color='success' onClick={handleSaveChanges}>
                            Add Topic
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
