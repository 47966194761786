import React, { useState, useEffect } from 'react'
import { Box, Typography, Card, CardContent, CardMedia, Button } from '@mui/material'
import DrawerNav from '../DrawerNav/DrawerNav'
import { BASE_URL } from '../../Api/Constants'
import { useNavigate } from 'react-router-dom'

export default function ViewOnboarding() {
    const [onboardingScreens, setOnboardingScreens] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        const fetchOnboardingScreens = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/onboarding`)
                if (response.ok) {
                    const data = await response.json()
                    setOnboardingScreens(data.data.onboardingList)
                } else {
                    console.error('Failed to fetch onboarding screens')
                }
            } catch (error) {
                console.error('Error:', error)
            } finally {
                setLoading(false)
            }
        }

        fetchOnboardingScreens()
    }, [])

    const handleEdit = (screenId) => {
        navigate('/onboarding?id=' + screenId)
    }

    const handleDelete = async (screenId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/onboarding/${screenId}`, {
                method: 'DELETE'
            })

            const data = await response.json()

            if (response.ok) {
                console.log(data) // Success message
                setOnboardingScreens((prevScreens) => prevScreens.filter((screen) => screen._id !== screenId))
            } else {
                console.error(data.message) // Error message
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3, color: '#524d4d' }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ fontWeight: 'bolder', mb: 4 }}>
                        Onboarding Screens
                    </Typography>
                    {loading ? (
                        <Typography sx={{ my: 2 }}>Loading...</Typography>
                    ) : onboardingScreens.length === 0 ? (
                        <Typography sx={{ my: 2 }}>No screens found!</Typography>
                    ) : (
                        <Box>
                            {onboardingScreens.map((screen, index) => (
                                <Card key={index} className='mb-8'>
                                    {screen.imageURL !== '' && <CardMedia component='img' className='h-32' image={screen.imageURL} alt={screen.title} />}
                                    <CardContent>
                                        <Typography gutterBottom variant='h5' component='div'>
                                            {screen.title}
                                        </Typography>
                                        <Typography variant='body2' color='text.secondary'>
                                            {screen.description}
                                        </Typography>
                                        <Box mt={2}>
                                            <Button variant='contained' color='primary' onClick={() => handleEdit(screen._id)} sx={{ mr: 2 }}>
                                                Edit
                                            </Button>
                                            <Button variant='contained' color='error' onClick={() => handleDelete(screen._id)}>
                                                Delete
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            ))}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
