/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { showTimeInAgoFormat } from '../../Utils/Time'
import { Box, Button, Card, CardContent, Snackbar, Stack, Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import checkIcon from '../../Assets/checked.png'
import DrawerNav from '../DrawerNav/DrawerNav'
import CustomThreeDots from '../Loader/CustomThreeDots'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function ViewSection() {
    const navigate = useNavigate()
    const handleNavigation = (route) => {
        navigate(route)
    }
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const sectionName = searchParams.get('name')

    const [contests, setContests] = useState([])
    const [topicsList, setTopicsList] = useState([])
    const [upcomingContests, setUpcomingContests] = useState([])
    const [liveContests, setLiveContests] = useState([])
    const [completedContests, setCompletedContests] = useState([])
    const [hiddenContests, setHiddenContests] = useState([])
    const [apiCalled, setApiCalled] = useState(false)
    const handleEditClick = (id) => {
        navigate('/edit-contest/' + id)
    }

    const [showLoader, setShowLoader] = useState(true)

    const getTopicsList = async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }

            await fetch(`${BASE_URL}/api/admin/topics/v2?section=${sectionName}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.status === 200) {
                        setTopicsList(data.data.topicsList)
                        setApiCalled(true)
                    } else {
                        setOpenErrorAlert(true)
                    }
                })
                .catch((error) => {
                    console.log('Error : ', error)
                    setOpenErrorAlert(true)
                    setApiCalled(true)
                })
        } catch {
        } finally {
            setShowLoader(false)
        }
    }

    const sectionsList = ['General Studies', 'Civil Services Aptitude', 'Current Affairs', 'Past Year Questions']

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const handleSectionClick = (text) => {
        navigate('/section?name=' + text)
    }

    const handleTopicClick = (text) => {
        navigate('/topic?name=' + text + '&section=' + sectionName)
    }

    useEffect(() => {
        if (!apiCalled) {
            getTopicsList()
        }
    })

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                {topicsList.length > 0 && (
                    <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                        <div className='pb-48'>
                            <h1 className='text-3xl font-bold pb-8'>Topics</h1>
                            <div className='grid md:grid-cols-4 gap-4'>
                                {topicsList.map((topic, index) => {
                                    return (
                                        <div className='flex justify-center items-center cursor-pointer' onClick={() => handleTopicClick(topic.topic)}>
                                            <div className='w-full max-w-sm rounded-lg shadow bg-gray-800 border-gray-700'>
                                                <div className='flex flex-col items-center pb-6'>
                                                    <img className='w-24 h-24 mb-3 mt-6 rounded-full shadow-lg' src='/vite.svg' alt='Bonnie image' />
                                                    <h5 className='mb-1 text-xl font-medium text-white'>{topic.topic}</h5>
                                                    <h5 className='mb-1 text-base text-white'>{topic.count} Questions </h5>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Box>
                )}
            </Box>
        </>
    )
}

export default ViewSection
