exports.filterList = [
    {
        label: 'Text',
        id: 'text'
    },
    {
        label: 'Image',
        id: 'image'
    }
]

exports.categoryList = [
    {
        label: 'None',
        id: 'none'
    },
    {
        label: 'Hazard Recognition',
        id: 'hazard_recognition'
    },
    {
        label: 'Knowledge',
        id: 'knowledge'
    },
    {
        label: 'Insight',
        id: 'insight'
    }
]

exports.yesNoList = [
    {
        label: 'Yes',
        id: 'yes'
    },
    {
        label: 'No',
        id: 'no'
    }
]