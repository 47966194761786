import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Button, TextField, Typography } from '@mui/material'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'

export default function DeleteQuestion() {
    const location = useLocation()
    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const questionText = searchParams.get('question')
    const action = 'delete'
    const questionId = searchParams.get('id')

    const handleDeleteTopic = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/question/${questionId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.ok) {
                navigate(-1)
            } else {
                throw new Error('Failed to modify subject')
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ marginY: 2 }}>
                        Delete Question
                    </Typography>
                    {action === 'delete' && (
                        <>
                            <TextField label='Question' value={questionText} fullWidth variant='outlined' margin='normal' disabled />
                            <Button sx={{ mt: 2 }} variant='contained' color='error' onClick={handleDeleteTopic}>
                                Delete Question
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
